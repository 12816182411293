import {
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  Toolbar,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
  Divider,
  Box,
  Button,
} from '@mui/material';
import React, { useState } from 'react';
import { shouldForwardProp } from '@mui/system';
import { IconSearch } from '@tabler/icons-react';
import LightButton from 'ui-component/button/LightButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import TuneIcon from '@mui/icons-material/Tune';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SecondaryButton from 'ui-component/button/SecondaryButton';
import DarkButton from 'ui-component/button/DarkButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    width: 250,
    paddingLeft: 16,
    paddingRight: 16,
    marginTop: 0,
    '& input': {
      background: 'transparent !important',
      paddingLeft: '4px !important',
      padding: 12,
    },
    [theme.breakpoints.down('lg')]: {
      width: 250,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 4,
      background:
        theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff',
    },
  })
);

function EnhancedTableToolbar(props) {
  const { selectedItem } = useSelector((state) => state.menu);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSetPreferences = () => {
    navigate('/f/preference-project/add');
  };

  const user = useSelector((state) => state.auth.authData.data?.userType);

  const handleClickAdd = () => {
    navigate('/project/add');
  };
  const handleCloseAdd = () => {
    setAnchorEl(null);
  };
  const calculatorMenuOptions = [
    {
      label: 'Tax Rebate Calculator',
      value: '/tax-calculator',
    },
    {
      label: 'Freelance Tax Calculator',
      value: '/freelance-tax-calculator',
    },
  ];
  const selectOption = (option) => {
    window.open(option.value);
  };
  const handleDropDownClick = (event) => {
    setAnchorEl((prev) => (prev ? null : event.currentTarget));
  };

  const title = (
    <Typography sx={{ flex: '1 1 100%' }} variant="h3" id="tableTitle">
      {props.title}
    </Typography>
  );

  const searchBox = (
    <OutlineInputStyle
      id="input-search-header"
      value={props?.search}
      onChange={(e) => props?.setSearch(e.target.value)}
      placeholder="Search for projects"
      startAdornment={
        <InputAdornment position="start">
          <IconSearch
            stroke={1.5}
            size="16px"
            color={theme.palette.grey[500]}
          />
        </InputAdornment>
      }
      aria-describedby="search-helper-text"
      inputProps={{ 'aria-label': 'weight' }}
    />
  );

  const preferencesBox = (
    <LightButton
      title="Preferences"
      startIcon={<TuneIcon />}
      onClick={handleSetPreferences}
      size="large"
    />
  );

  const filterBox = (
    <>
      <LightButton
        title="Filters"
        startIcon={<FilterListIcon />}
        onClick={handleClick}
        size="large"
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>Filter 1</MenuItem>
        <MenuItem onClick={handleClose}>Filter 2</MenuItem>
        <MenuItem onClick={handleClose}>Filter 3</MenuItem>
      </Menu>
    </>
  );

  const headMenus = (
    <Stack
      direction={'row'}
      sx={{ width: '100%' }}
      alignItems={'end'}
      justifyContent={'flex-end'}
      gap={1}
    >
      <SecondaryButton
        // color={user?.userTypeId === 2 ? 'red' : ''}
        title={'Calculators'}
        onClick={handleDropDownClick}
        // fullWidth
        endIcon={anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      />
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseAdd}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ top: '60px' }}
      >
        {calculatorMenuOptions.map((option, index) => (
          <MenuItem key={option.label} onClick={() => selectOption(option)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      {user?.userTypeId === 1 && pathname.includes('/c/project') && (
        <>
          <DarkButton
            title="Add New Project"
            onClick={handleClickAdd}
            startIcon={
              <AddIcon sx={{ color: theme.palette.background.paper }} />
            }
          />
        </>
      )}
    </Stack>
  );

  return (
    <Toolbar
      sx={{
        py: 1,
        px: 0,
        display: 'block',
      }}
    >
      {!isSmallDevice ? (
        <Stack direction={'column'} gap={1}>
          {headMenus}
          <Stack direction={'row'} alignItems={'center'} gap={2} mb={2}>
            {title}
            {searchBox}
            {selectedItem[0] === 'projectMatchingPreference' && preferencesBox}
            {/* {filterBox} */}
          </Stack>
        </Stack>
      ) : (
        <Stack direction={'column'} gap={2} mb={2}>
          {headMenus}
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            {title}
            {searchBox}
          </Stack>
          {/* <Stack
            direction={"row"}
            gap={2}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            {selectedItem[0] === "projectMatchingPreference" && preferencesBox}
            {filterBox}
          </Stack> */}
        </Stack>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const TableHeader = (props) => {
  return <EnhancedTableToolbar {...props} />;
};

export default TableHeader;
