import React, { useState } from 'react';
import {
  AppBar,
  Box,
  MenuItem,
  Toolbar,
  Tooltip,
  tooltipClasses,
  Typography,
  Menu,
} from '@mui/material';
import { styled } from '@mui/system';
import HelpIcon from 'assets/images/svg/help.svg';
import LogoSection from 'ui-component/logoSection';

import { Outlet } from 'react-router-dom';
import Footer from 'layout/LandingLayout/Footer/Index';

const StickyAppBar = styled(AppBar)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.background.bgMain,
  boxShadow: 'none',
  borderBottom: '1px solid rgba(35, 35, 35, 0.1)',
}));

const MenuItemWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),

  ':hover': {
    borderRadius: theme.spacing(0.5),
  },
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.9) !important', // semi-transparent black
  },
}));

const NoLayout = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StickyAppBar>
        {/* <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        > */}
          {/* <LogoSection /> */}

          {/* <MenuItemWrapper>
            <Box component={'img'} src={HelpIcon} alt="HelpIcon" />
            <BootstrapTooltip title="Contact support@prodculator.com for assistance">
              <Typography variant="h4" fontWeight={500}>
                <a
                  href="#"
                  onClick={handleClick}
                  style={{ color: 'inherit', textDecoration: 'none' }}
                >
                  Need help?
                </a>
              </Typography>
            </BootstrapTooltip>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              disableScrollLock
              MenuListProps={{
                sx: {
                  width: '100px',
                },
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{
                sx: {
                  position: 'absolute',
                },
              }}
            >
              <MenuItem
                sx={{ justifyContent: 'center' }}
                onClick={() => window.location.href = 'mailto:support@prodculator.com'}
              >
                Send Email
              </MenuItem>
              <MenuItem
                sx={{ justifyContent: 'center' }}
                onClick={() => window.location.href = 'https://boltin.io/'}
              >
                FAQs
              </MenuItem>
            </Menu>
          </MenuItemWrapper> */}
        {/* </Toolbar> */}
      </StickyAppBar>
      <Outlet />
      {/* <Footer /> */}
    </>
  );
};

export default NoLayout;