import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

// material-ui
import {
  ButtonBase,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project imports
import LAYOUT_CONST from "constant";
import useConfig from "hooks/useConfig";
import { useDispatch, useSelector } from "store";
import { activeID, activeItem, openDrawer } from "store/slices/menu";
import SideIcon from "assets/images/svg/side-indicator.svg";
// assets
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import menuItems from "menu-items";
import { getProfile } from 'store/slices/Auth';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level, parentId, isParents = false }) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
  const user = useSelector((state) => state.auth.authData.data);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { layout, borderRadius } = useConfig();

  const { selectedItem, drawerOpen } = useSelector((state) => state.menu);
  const isSelected = selectedItem.findIndex((id) => id === item.id) > -1;
  useEffect(() => {
    let selectedRoute = '';
    if (menuItems?.items[0].children) {
      selectedRoute =
        menuItems &&
        menuItems?.items[0].children.find((item) =>
          pathname.includes(item.url)
        ); // Add a semicolon at the end of this line
    }
    dispatch(activeItem([selectedRoute?.id]));
  }, [menuItems]);

  const Icon = item?.icon;
  const itemIcon = item?.icon ? (
    <Icon
      stroke={1.5}
      size={'20px'}
      style={{
        color: isSelected
          ? user?.userType?.name === 'Funder'
            ? theme.palette.secondary.main
            : theme.palette.grey.textColor
          : user?.userType?.name === 'Funder'
          ? theme.palette.common.white
          : theme.palette.text.primary,
        ...(layout === LAYOUT_CONST.HORIZONTAL_LAYOUT &&
          isParents && { fontSize: 20, stroke: '1.5' }),
      }}
    />
  ) : (
    <FiberManualRecordIcon
      sx={{
        color: isSelected
          ? theme.palette.secondary.main
          : theme.palette.text.primary,
        width: selectedItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
        height: selectedItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }
  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  const itemHandler = (id) => {
    dispatch(activeItem([id]));
    if (matchesSM) dispatch(openDrawer(false));
    dispatch(activeID(parentId));
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch(activeItem([item.id]));
    }
    // eslint-disable-next-line
  }, [pathname]);

  const textColor = 'text.primary';
  const iconSelectedColor = 'text.primary';

  return (
    <>
      <ListItemButton
        component={Link}
        to={item.url}
        target={itemTarget}
        disabled={item.disabled}
        sx={{
          zIndex: 1201,
          borderRadius: '12px',
          mb: 0.5,
          py: 0.75,
          width: '100%',
          position: 'relative',

          ...(level === 1 && {
            '&:hover': {
              background:
                user?.userType?.name === 'Funder'
                  ? 'rgba(255, 255, 255, 0.05)'
                  : theme.palette.secondary.light,
              color: theme.palette.grey[900],
            },
            '&.Mui-selected': {
              background:
                user?.userType?.name === 'Funder'
                  ? 'rgba(255, 255, 255, 0.05)'
                  : theme.palette.secondary.main,
              color:
                user?.userType?.name === 'Funder'
                  ? theme.palette.common.white
                  : theme.palette.grey[900],
              position: 'relative',
              width: '100%',
              '&:hover': {
                color: theme.palette.grey[900],
                background:
                  user?.userType?.name === 'Funder'
                    ? 'rgba(255, 255, 255, 0.05)'
                    : theme.palette.secondary.main,
              },
              ':before': {
                content: '""',
                backgroundImage: `url(${SideIcon})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right',
                position: 'absolute',
                height: '100%',
                width: '100%',
                right: -16,
                top: 1,
              },
            },
          }),
          ...(level !== 1 && {
            py: level === 1 ? 0 : 1,
            '&:hover': {
              bgcolor: 'transparent',
            },
            '&.Mui-selected': {
              '&:hover': {
                bgcolor: 'transparent',
              },
              bgcolor: 'transparent',
            },
          }),
        }}
        selected={isSelected}
        onClick={() => itemHandler(item.id)}
      >
        <ButtonBase
          aria-label="theme-icon"
          sx={{ borderRadius: `${borderRadius}px` }}
        >
          <ListItemIcon
            sx={{
              // minWidth: level === 1 ? 36 : 18,
              color: isSelected ? iconSelectedColor : textColor,
              ...(level === 1 && {
                borderRadius: `${borderRadius}px`,
                width: 46,
                height: 46,
                alignItems: 'center',
                justifyContent: 'center',
              }),
            }}
          >
            {itemIcon}
          </ListItemIcon>
        </ButtonBase>

        <ListItemText
          primary={
            <Typography
              variant={isSelected ? 'h5' : 'body1'}
              sx={{
                color:
                  user?.userType?.name === 'Funder'
                    ? theme.palette.common.white
                    : textColor,
              }}
            >
              {item.title}
            </Typography>
          }
        />
      </ListItemButton>
    </>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
  parentId: PropTypes.string,
  isParents: PropTypes.bool,
};

export default NavItem;
