import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";  

const AboutUsFAQ = () => {
  const handleBackClick = () => {
    window.history.back();
  };
  return (
    <div
      style={{
        maxWidth: '900px',
        margin: 'auto',
        padding: '20px',
        boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.3)',
      }}
    >
      <Grid container alignItems="center">
        <Grid item>
          <Button startIcon={<ArrowBackIcon />} onClick={handleBackClick}>
            Back
          </Button>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="h2" align="center" gutterBottom>
            About Us
          </Typography>
        </Grid>
      </Grid>

      <Accordion style={{ marginTop: '25px' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h3">About Prodculator</Typography>
        </AccordionSummary>
        <Accordion style={{ width: '90%', margin: 'auto' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h4">What is Prodculator?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Prodculator is a fintech platform specifically designed for
              funders and project owners in the film, theatre, technology, and
              event industries. It connects these project owners with funders
              ready to finance their innovative projects. As a dynamic resource
              platform and community, Prodculator enables creative entrepreneurs
              to network, access industry updates, utilize tax incentive
              calculators, and showcase their projects on a global scale.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Accordion>

      <Accordion style={{ marginTop: '25px' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h3">Detailed Services</Typography>
        </AccordionSummary>
        <Accordion style={{ padding: '5px', width: '90%', margin: 'auto' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h4">
              What specific services does Prodculator offer project owners and
              funders?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={5}>
              Prodculator offers the following services:
              <br />
              - Tax Rebate and Incentive Calculator: Provides free tax rebate
              calculations for various productions across different countries,
              helping creatives plan financially for their projects.
              <br />
              - Global Funding Opportunities: Allows project owners worldwide to
              connect with funders interested in supporting projects within the
              creative and tech industries. <br />
              - Social Networking and Updates: Functions as a social platform
              where registered members can interact, share insights, and stay
              current with the latest trends in the creative industries.
              <br />
              - Resource Page: Offers valuable industry-related resources,
              links, and tools to support projects and enhance knowledge. This
              includes educational content, industry links, tool
              recommendations, networking opportunities, and legal and financial
              guidance.
              <br />
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Accordion>

      <Accordion style={{ marginTop: '25px' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h3">Tax Rebate/Incentive Calculator</Typography>
        </AccordionSummary>
        <Accordion style={{ width: '90%', margin: 'auto' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h4">
              How relible are the tax rebate calculations provided by
              Prodculator?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={5}>
              While we ensure our data and formulas are up-to-date, the tax
              rebate calculations are for informational purposes only and should
              not be considered definitive financial advice.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ width: '90%', margin: 'auto' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h4">
              How does the tax rebate/incentive calculator work?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={5}>
              Our calculator allows you to estimate potential tax rebates or
              incentives for your film or post-production projects based on
              current laws in the United Kingdom and South Africa. Enter your
              project details to receive an estimated rebate.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ width: '90%', margin: 'auto' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h4">
              Are there plans to include more countries in the tax rabate
              calculator?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={5}>
              Yes, we are actively working to expand our calculator to include
              tax incentives and rebates from additional countries.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Accordion>

      <Accordion style={{ marginTop: '25px' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h3">Funding Page</Typography>
        </AccordionSummary>
        <Accordion style={{ width: '90%', margin: 'auto' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h4">
              How can I post my project for funding?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={5}>
              If you're a project owner entrepreneur, create an account,
              complete your project profile, and provide detailed information
              about your funding needs to showcase your project to potential
              funders.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ width: '90%', margin: 'auto' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h4">
              How can funders post their interests?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={5}>
              Funders can create profiles on our platform, specifying the types
              of projects they are interested in funding, including preferred
              sectors and investment ranges.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ width: '90%', margin: 'auto' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h4">
              Are there any fees charged for successful funding deals?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={5}>
              No, we do not charge fees on successful deals. Our fee structure
              is based solely on the posting of projects, allowing for
              transparent and straightforward cost planning.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Accordion>

      <Accordion style={{ marginTop: '25px' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h3">Networking and Community</Typography>
        </AccordionSummary>
        <Accordion style={{ width: '90%', margin: 'auto' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h4">
              How can Prodculator help me network with other professionals in
              the creative industries?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Prodculator serves as a vibrant community hub, facilitating
              connections with other creative professionals, engaging in
              discussions, sharing projects, and receiving feedback.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Accordion>

      <Accordion style={{ marginTop: '25px' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h3">General Questions</Typography>
        </AccordionSummary>
        <Accordion style={{ width: '90%', margin: 'auto' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h4">
              How do I join the Prodculator community?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={5}>
              Register on our platform to start connecting with funders, use the
              tax rebate calculator, and participate in community discussions
              and networking opportunities.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ width: '90%', margin: 'auto' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h4">
              Who can benefit from using Prodculator?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={5}>
              Prodculator is designed for anyone in the creative industries,
              including filmmakers, theatre producers, event organizers, tech
              innovators, and creative entrepreneurs seeking funding and
              resources
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ width: '90%', margin: 'auto' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h4">
              What can I find on the Resource page?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontWeight={5}>
              The Resource Page includes tutorials, webinars, articles, direct
              links to important industry websites, reviews and links to
              essential software and tools, information about upcoming industry
              events, and guidance on legal and financial matters.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Accordion>
    </div>
  );
};

export default AboutUsFAQ;
