import React, { useState } from "react";
import { Box, Button, Typography, Modal, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { ReactComponent as CookieSvg } from 'assets/images/svg/cookie.svg';
import ManageCookieConsent from './ManageCookieConsent'; // Import the ManageCookieConsent component
import {  Menu, MenuItem } from '@mui/material';// Import the ManageCookieConsent component
import { KeyboardArrowDown as ArrowDownIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
const CookieModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const CookieBox = styled(Box)(({ theme }) => ({
  maxWidth: '580px',
  backgroundColor: 'white',
  borderRadius: '10px',
  padding: '20px',
  textAlign: 'center',
  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
  position: 'relative',

  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
}));

const CookieButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: '#000',
  fontWeight: 'bold',
  minWidth: 140,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));
const RejectButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: '#000',
  fontWeight: 'bold',
  minWidth: 140,
  '&:hover': {
    backgroundColor: theme.palette.error.main,
  },
}));

const PrivacyButton = styled(Typography)(({ theme }) => ({
  color: '#000',
  cursor: 'pointer',
  fontSize: '14px',
  fontWeight: '400',
  textDecoration: 'underline',
  textTransform: 'none',
}));

export default function CookieConsent({ isOpen, setIsOpen, onConsent }) {
  const [isManageOpen, setIsManageOpen] = useState(false);
  const [privacyMenuAnchor, setPrivacyMenuAnchor] = useState(null);
  const navigate = useNavigate();
  const handleReject = () => {
    setIsOpen(false);
    setIsManageOpen(true);
  };

  const handlePrivacyClick = (event) => {
    setPrivacyMenuAnchor(event.currentTarget);
  };
  const handleClosePrivacyMenu = () => {
    setPrivacyMenuAnchor(null);
  };

  const handleNavigateUkPrivacy = () => {
    navigate('/uk-privacy-policy');
    setPrivacyMenuAnchor(null);
  }

  const handleNavigateUsPrivacy = () => {
    navigate('/us-privacy-policy');
    setPrivacyMenuAnchor(null);
  }

  return (
    <>
      <CookieModal open={isOpen}>
        <CookieBox>
          <CookieSvg />

          <Stack gap={2} my={5}>
            <Typography variant="h1" fontWeight="600" color="black">
              Our website use Cookies
            </Typography>
            <Typography variant="h5" sx={{ color: '#484848' }}>
              We use cookies and similar technologies to help personalise content,
              tailor and measure ads, and provide a better experience. By clicking
              OK or turning an option on in Cookie Preferences, you agree to this,
              as outlined in our Cookie Policy.
            </Typography>
          </Stack>

          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            alignItems={'baseline'}
            gap={2}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PrivacyButton
                  onClick={handlePrivacyClick}
                  sx={{
                    textTransform: 'none',
                    padding: 0,
                    minWidth: 'auto',
                    color: 'inherit',
                    '&:hover': { textDecoration: 'underline' },
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="body1" sx={{ mr: 0.5 }}>
                    Privacy policy
                  </Typography>
                  <ArrowDownIcon fontSize="small" />
                </PrivacyButton>
                <Menu
                  anchorEl={privacyMenuAnchor}
                  open={Boolean(privacyMenuAnchor)}
                  onClose={handleClosePrivacyMenu}
                  transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                >
                  <MenuItem
                    component="a"
                    
                    target="_blank"
                    onClick={handleNavigateUkPrivacy}
                    sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}
                  >
                    UK Privacy Policy
                  </MenuItem>
                  <MenuItem
                    component="a"
                    
                    target="_blank"
                    onClick={handleNavigateUsPrivacy}
                    sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}
                  >
                    US Privacy Policy
                  </MenuItem>
                </Menu>
              </Box>
            <CookieButton variant="contained" onClick={onConsent}>
              OK
            </CookieButton>
            <RejectButton variant="contained" onClick={handleReject}>
              Reject
            </RejectButton>
          </Stack>
        </CookieBox>
      </CookieModal>
      <ManageCookieConsent isOpen={isManageOpen} setIsOpen={setIsManageOpen} setCookieConsentOpen={setIsOpen} onConsent={onConsent} />
    </>
  );
}