import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as React from 'react';

import { IconButton, Menu, MenuItem, Stack, styled } from '@mui/material';
import moment from 'moment';
import { useDispatch } from 'store';
import Loader from 'ui-component/Loader';
import { Confirmation } from 'ui-component/conformation';
import TableHeader from './TableHeader';
import { deleteProject } from 'store/slices/project';
import { openSnackbar } from 'store/slices/snackbar';
import { useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const EllipsisTypography = styled(Typography)(() => ({
  width: '200px',
  whiteSpace: 'normal',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
}));
const headCells = [
  {
    id: 'listContents',
    numeric: false,
    disablePadding: true,
    label: 'Id',
  },
  {
    id: 'projectName',
    numeric: false,
    disablePadding: false,
    label: 'Project Name',
  },
  {
    id: 'projectDescription',
    numeric: false,
    disablePadding: false,
    label: 'Project Description',
  },
  {
    id: 'creatorName',
    numeric: false,
    disablePadding: false,
    label: 'Creator Name',
  },
  {
    id: 'createdOn',
    numeric: true,
    disablePadding: false,
    label: 'Created On',
  },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id}>{headCell.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ProjectTable({
  title,
  rows,
  fetchData,
  search,
  setSearch,
  totalPage,
  handleRedirect,
  projectType,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState('DEC');
  const [selectedSlug, setSelectedSlug] = React.useState(null);
  const [orderBy, setOrderBy] = React.useState('createdAt');
  const [isLoader, setIsLoader] = React.useState(false);
  const [selected, setSelected] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const user = useSelector((state) => state.auth.authData.data?.userType);

  React.useEffect(() => {
    setIsLoader(true);
    fetchData(page, rowsPerPage, orderBy, order, search);
  }, [dispatch, page, rowsPerPage, orderBy, order, search, pathname]);

  React.useEffect(() => {
    if (user.userTypeId === 1) {
      const existingHeaderAction = headCells.find(
        (item) => item.id === 'Action'
      );
      const existingHeaderStatus = headCells.find(
        (item) => item.id === 'Status'
      );
      if (!existingHeaderStatus) {
        headCells.push({
          id: 'Status',
          numeric: true,
          disablePadding: false,
          label: 'Status',
        });
      }
      if (!existingHeaderAction) {
        headCells.push({
          id: 'Action',
          numeric: true,
          disablePadding: false,
          label: 'Action',
        });
      }
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setOpenDialog(true);
  };
  const handleEdit = () => {
    navigate(`/project/edit/${selectedSlug}`);
  };

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoader(false);
    }, 500);
  }, [rows]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    debugger;
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleConfirmDelete = () => {
    handleClose();
    setOpenDialog(false);
    dispatch(deleteProject(selected))
      .then((res) => {
        if (res.code === 200) {
          fetchData(page, rowsPerPage, orderBy, order, search);
          dispatch(
            openSnackbar({
              open: true,
              message: 'Project deleted successfully',
              variant: 'alert',
              alert: {
                color: 'success',
              },
              close: false,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          openSnackbar({
            open: true,
            message: err || 'Error delete project',
            variant: 'alert',
            alert: {
              color: 'error',
            },
            close: false,
          })
        );
      });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          backgroundColor: theme.palette.background.bgMain,
          width: '100%',
          mb: 2,
        }}
      >
        <TableHeader
          title={title}
          setSearch={setSearch}
          projectType={projectType}
        />
        {isLoader ? (
          <Loader />
        ) : (
          <>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {rows.length ? (
                    rows.map((row, index) => (
                      <TableRow
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        sx={{
                          cursor: 'pointer',
                          height: 'auto',
                        }}
                      >
                        <TableCell></TableCell>
                        <TableCell>{row?.id}</TableCell>
                        <TableCell
                          onClick={() => {
                            handleRedirect(row);
                          }}
                        >
                          <EllipsisTypography variant="body2">
                            {row?.projectTitle}
                          </EllipsisTypography>
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            handleRedirect(row);
                          }}
                        >
                          <EllipsisTypography variant="body2">
                            {row?.projectSummary}
                          </EllipsisTypography>
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            handleRedirect(row);
                          }}
                        >
                          <EllipsisTypography>
                            {row?.firstName + ' ' + row?.lastName}
                          </EllipsisTypography>
                        </TableCell>

                        <TableCell
                          onClick={() => {
                            handleRedirect(row);
                          }}
                        >
                          <Stack direction={'column'} gap={0.5}>
                            <Typography variant="h5">
                              {moment(row?.createdAt).format('DD MMM, YYYY')}
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{ color: '#23232299' }}
                            >
                              {moment(row?.createdAt).format('hh:mm A')}
                            </Typography>
                          </Stack>
                        </TableCell>

                        {user.userTypeId === 1 && (
                          <>
                            <TableCell
                              onClick={() => {
                                handleRedirect(row);
                              }}
                            >
                              {(row?.projectType === 'Approve' && 'Approved') ||
                                (row?.projectType === 'Reject' && 'Rejected') ||
                                (row?.projectType === 'Review' && 'Review') ||
                                (row?.projectType === 'Draft' && 'Draft')}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={(e) => {
                                  setSelected(row?.id);
                                  setSelectedSlug(row?.slugId);
                                  handleClick(e);
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                              >
                                <MenuItem onClick={handleEdit}>Edit</MenuItem>
                                <MenuItem onClick={handleDelete}>
                                  Delete
                                </MenuItem>
                              </Menu>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={headCells.length + 2} align="center">
                        <Box sx={{ fontSize: '1.25rem', mt: 3 }}>
                          No Projects Found
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalPage}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Paper>
      <Confirmation
        message={`Are you sure you want to delete this project?`}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        handleConfirm={handleConfirmDelete}
      />
    </Box>
  );
}
