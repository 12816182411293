import { Box, List, MenuItem, Stack, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MenuItemWrapper = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  ...theme.typography.h4,

  ":hover": {
    borderRadius: theme.spacing(0.5),
  },
}));

const menuItems = [
  { label: 'About us', path: '/about-us' },
  { label: 'Support', path: '/home', id: 'contact-us' },
];

const HeaderLinkSection = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClick = (path, id) => {
    navigate(path);
    setTimeout(() => {
      scrollToSection(id);
    }, 100);
  };

  const renderMenuItems = () => {
    return (
      <>
        <Box
          key={'About us'}
          style={{ textDecoration: 'none' }}
          onClick={() => handleClick('/about-us', '')}
        >
          <MenuItemWrapper>{'About us'}</MenuItemWrapper>
        </Box>
        <Box
          key={'Support'}
          style={{ textDecoration: 'none' }}
          onClick={() => handleClick('/', 'contact-us')}
        >
          <MenuItemWrapper>{'Support'}</MenuItemWrapper>
        </Box>
      </>
    );
  };
    
     
    

  return (
    <Stack
      direction="row"
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        flexGrow: 1,
        display: matchDownMd ? "none" : "flex",
        spacing: { xs: 1.5, md: 2.5 },
      }}
    >
      <List sx={{ display: "flex", gap: 3 }}>{renderMenuItems()}</List>
    </Stack>
  );
};

export default HeaderLinkSection;