import { lazy } from "react";

// project imports
import NoLayout from "layout/NoLayout";
import Loadable from "ui-component/Loadable";
import GuestGuard from "utils/route-guard/GuestGuard";
import NavMotion from "layout/NavMotion";
import AboutUsFAQ from "views/common/AboutUsFaqs";


const PrivacyPolicy = Loadable(lazy(() => import('../views/common/UkPrivacyPolicy')));
const PrivacyPolicyUs = Loadable(lazy(() => import('../views/common/UsPrivacyPolicy')));
const Faqs = Loadable(lazy(() => import('../views/common/Faqs')));
const PrivacyPolicyCalifornia = Loadable(lazy(() => import('../views/common/ColiforniaPolicy')));
const Privacy = {
  path: '/',
  element: (
    <NavMotion>
      <GuestGuard>
        
      <NoLayout />
      </GuestGuard>
      
    </NavMotion>
  ),
  children: [
    {
      path: '/uk-privacy-policy',
      element: <PrivacyPolicy />,
      
    },
    {
      path: '/us-privacy-policy',
      element: <PrivacyPolicyUs />,
    },
    {
      path: '/California-privacy-policy',
      element: <PrivacyPolicyCalifornia />,
    },
    {
      path: '/faqs',
      element: <Faqs />,
    },
    {
      path: '/about-us',
      element: <AboutUsFAQ />,
    }
  ],
};

export default Privacy;
