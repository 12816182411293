import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import StarProject from 'views/funder/starProject';
import Member from 'views/creative/members';
import MembersView from 'views/creative/members/member-view';


const Project = Loadable(lazy(() => import('views/creative/project')));
const ProjectView = Loadable(lazy(() => import('views/common/project-view')));
const AllProject = Loadable(lazy(() => import('views/funder/allProject')));
const Resources = Loadable(lazy(() => import('views/funder/resources')));
const ResourceView = Loadable(lazy(() => import('views/common/resource-view')));

const PreferenceProject = Loadable(
  lazy(() => import('views/funder/preferenceProject'))
);

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/c/project',
      element: <Project />,
    },
    {
      path: '/c/project/:projectName',
      element: <ProjectView />,
    },
    // {
    //   path: '/c/member/',
    //   element: <Member />,
    // },
    {
      path: '/c/member/:memberName',
      element: <MembersView />,
    },
    {
      path: '/c/resources',
      element: <Resources />,
    },
    {
      path: '/c/resources/:resourceName',
      element: <ResourceView />,
    },
    {
      path: '/f/project/:projectName',
      element: <ProjectView />,
    },
    {
      path: '/f/star-project/:projectName',
      element: <ProjectView />,
    },
    {
      path: '/f/preference-project/:projectName',
      element: <ProjectView />,
    },
    {
      path: '/f/resources',
      element: <Resources />,
    },
    {
      path: '/f/resources/:resourceName',
      element: <ResourceView />,
    },
    {
      path: '/project/:projectName',
      element: <ProjectView />,
    },
    {
      path: '/f/project',
      element: <AllProject />,
    },
    {
      path: '/f/preference-project',
      element: <PreferenceProject />,
    },
    {
      path: '/f/star-project',
      element: <StarProject />,
    },
  ],
};

export default MainRoutes;
