import { Box, Grid, Stack, styled, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as ChRight } from "assets/images/svg/ch-right.svg";

import { ReactComponent as FilmSectorIcon } from "assets/images/sector/film.svg";
import { ReactComponent as MusicSectorIcon } from "assets/images/sector/music.svg";
import { ReactComponent as TvSectorIcon } from 'assets/images/sector/tv.svg';
import { ReactComponent as CreativeEconomySectorIcon } from 'assets/images/sector/ce.svg';
import { ReactComponent as EventSectorIcon } from 'assets/images/sector/event.svg';

const SectionWrapper = styled(Box)(({ theme }) => ({
  padding: '150px 60px',
  background: theme.palette.background.paper,
  position: 'relative',
  zIndex: 1,
  // minHeight: "80vh",
  width: '100%',
  overflow: 'hidden',

  [theme.breakpoints.down('md')]: {
    padding: '50px 30px',
  },
}));

const MainTitle = styled(Typography)(({ theme }) => ({
  // fontFamily: 'Baskervville',
  fontWeight: 'bold',
  fontSize: '40px',
  lineHeight: '48px',
  color: '#111111',

  [theme.breakpoints.down('md')]: {
    fontSize: '26px',
    lineHeight: '35px',
  },
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  fontWeight: '400',
  color: '#111111B2',
  maxWidth: '400px',
}));

const WorkCardBox = styled(Box)(({ theme }) => ({
  padding: '28px 20px 20px',
  background: theme.palette.background.paper,
  border: '1px solid #1111111A',
  borderRadius: theme.spacing(1.5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  color: '#111111',
}));

const CardSubTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  color: '#11111199',
  fontWeight: '400',
}));

const LearnBtnWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
  cursor: 'pointer',
  width: 'fit-content',
  marginTop: theme.spacing(2),
}));

const LearnTxt = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '24px',
  width: 'fit-content',
}));

const ChRightIcon = styled(ChRight)(({ theme }) => ({
  path: {
    fill: theme.palette.secondary.main,
  },
}));

const SectorSection = () => {
  const creativeSectorsData = [
    {
      icon: <FilmSectorIcon />,
      title: 'Film/ Television & Gaming',
      subtitle:
        'Establish direct access to global funders and secure valuable grants worldwide.',
    },
    {
      icon: <MusicSectorIcon />,
      title: 'Music',
      subtitle:
        'Composers, producers, and record label owners. Establish direct access to funders and secure valuable grants worldwide',
    },
    {
      icon: <CreativeEconomySectorIcon />,
      title: 'Service Providers',
      subtitle:
        'Secure funding and grants for your business ideas within the creative service industry, including sectors like logistics, legal, accounting, and more',
    },
    // {
    //   icon: <CreativeEconomySectorIcon />,
    //   title: 'Creative Economy',
    //   subtitle:
    //     'Complete full membership registration to qualify for pitching your project.',
    // },
    {
      icon: <EventSectorIcon />,
      title: 'Events',
      subtitle:
        'Secure funding for your live events,  large-scale productions. Utilize our tools to maximize financial opportunities.',
    },
  ];

  return (
    <SectionWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12} mb={4}>
          <Stack
            gap={2}
            alignItems={'center'}
            justifyContent={'center'}
            textAlign={'center'}
          >
            <MainTitle>Empowering diverse Creative sectors</MainTitle>
            <SubTitle>
              Prodculator connects creativepreneurs with funders, enabling
              seamless project funding.
            </SubTitle>
          </Stack>
        </Grid>
        {creativeSectorsData.map((item, index) => (
          <Grid item xs={12} sm={6} md={3}>
            <WorkCardBox>
              <Stack>
                {item.icon}
                <Stack gap={1.5} mt={3}>
                  <CardTitle>{item.title}</CardTitle>
                  <CardSubTitle>{item.subtitle}</CardSubTitle>
                </Stack>
              </Stack>

              {/* <LearnBtnWrapper>
                <LearnTxt>Learn more</LearnTxt>
                <ChRightIcon />
              </LearnBtnWrapper> */}
            </WorkCardBox>
          </Grid>
        ))}
      </Grid>
    </SectionWrapper>
  );
};

export default SectorSection;
