import { Box, Button, Grid, Stack, styled, Typography } from "@mui/material";
import React, { useState } from "react";

import BlueBg from "assets/images/svg/blue-round.svg";
import YellowBg from "assets/images/svg/yellow-round.svg";
import { ReactComponent as ChRight } from "assets/images/svg/ch-right.svg";

import { ReactComponent as FunderIcon1 } from "assets/images/how/f1.svg";
import { ReactComponent as FunderIcon2 } from "assets/images/how/f2.svg";
import { ReactComponent as FunderIcon3 } from "assets/images/how/f3.svg";
import { ReactComponent as FunderIcon4 } from "assets/images/how/f4.svg";

import { ReactComponent as CreativeIcon1 } from "assets/images/how/c1.svg";
import { ReactComponent as CreativeIcon2 } from "assets/images/how/c2.svg";
import { ReactComponent as CreativeIcon3 } from "assets/images/how/c3.svg";
import { ReactComponent as CreativeIcon4 } from "assets/images/how/c4.svg";
// import { ReactComponent as CreativeIcon5 } from "assets/images/how/c5.svg";

const SectionWrapper = styled(Box)(({ theme }) => ({
  padding: "150px 60px",
  background: theme.palette.background.black,
  position: "relative",
  zIndex: 1,
  minHeight: "80vh",
  width: "100%",
  overflow: "hidden",

  ":before": {
    content: '""',
    position: "absolute",
    zIndex: -1,
    top: "-200px",
    left: "-200px",
    width: "600px",
    height: "600px",
    background: `url(${BlueBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top left",
  },

  ":after": {
    content: '""',
    position: "absolute",
    zIndex: -1,
    bottom: "-200px",
    right: "-200px",
    width: "600px",
    height: "600px",
    background: `url(${YellowBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom right",
  },

  [theme.breakpoints.down("md")]: {
    padding: "50px 30px",
  },
}));

const MainTitle = styled(Typography)(({ theme }) => ({
  // fontFamily: 'Baskervville',
  fontWeight: "bold",
  fontSize: "40px",
  lineHeight: "48px",
  color: theme.palette.background.paper,
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  color: "#FFFFFFB2",
  fontWeight: "400",
}));

const WorkCardBox = styled(Box)(({ theme }) => ({
  padding: "28px 20px 20px",
  background: "rgba(22,22,22,0.5)",
  border: "1px solid #FFFFFF1A",
  borderRadius: theme.spacing(1.5),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  color: theme.palette.background.paper,
}));

const CardSubTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  color: "#FFFFFF99",
  fontWeight: "400",
}));

const LearnBtnWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1),
  cursor: "pointer",
  width: "fit-content",
  marginTop: theme.spacing(2),
}));

const LearnTxt = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "24px",
  width: "fit-content",
}));

const ChRightIcon = styled(ChRight)(({ theme }) => ({
  path: {
    fill: theme.palette.secondary.main,
  },
}));

//
const TabContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  overflow: "hidden",
  background: "rgba(22,22,22,0.5)",
  border: "1px solid #FFFFFF1A",
  width: "fit-content",
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(1),
  gap: theme.spacing(1),
}));

const TabButton = styled(Box)(({ theme, active }) => ({
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1.5, 3),
  cursor: "pointer",
  color: active ? "#111111" : "#FFFFFFB2",
  backgroundColor: active ? theme.palette.secondary.main : "transparent",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "bold",
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: active ? theme.palette.secondary.main : "#333",
  },
}));

const HowWorks = () => {
  const [activeTab, setActiveTab] = useState("creative");

  const creativeData = [
    {
      icon: <CreativeIcon1 />,
      title: "Global Funding Access",
      subtitle:
        "Establish direct access to global funders and secure valuable grants worldwide.",
    },
    {
      icon: <CreativeIcon2 />,
      title: "Industry Support",
      subtitle:
        "Supports a range of creative fields including film, TV, stage, music, education, and more.",
    },
    {
      icon: <CreativeIcon3 />,
      title: "Registration",
      subtitle:
        "Complete full membership registration to gain access to our funders and pitch your projects.",
    },
    {
      icon: <CreativeIcon4 />,
      title: "Calculate Tax Incentives",
      subtitle:
        "Use our calculator to estimate potential tax rebates and incentives quickly and accurately.",
    },
    // {
    //   icon: <CreativeIcon5 />,
    //   title: "Effortless Pitching",
    //   subtitle:
    //     "Effortlessly present your projects and capture attention with a compelling pitch.",
    // },
  ];

  const fundersData = [
    {
      icon: <FunderIcon1 />,
      title: 'Global Project Match',
      subtitle:
        'Find projects worldwide that align with your funding and ROI objectives.',
    },
    {
      icon: <FunderIcon2 />,
      title: 'Direct Outreach',
      subtitle: 'Ability to directly contact project owners.',
    },
    {
      icon: <FunderIcon3 />,
      title: 'Instant Notification',
      subtitle:
        'Get instant alerts for projects that match your criteria as soon as they become available.',
    },
    {
      icon: <FunderIcon4 />,
      title: 'Easy Registration',
      subtitle:
        'The first step to a more productive experience is our easy and stress free registration process.',
    },
  ];

  const renderCards = (data) =>
    data.map((item, index) => (
      <Grid item xs={12} sm={6} md={3} key={index}>
        <WorkCardBox>
          <Stack>
            {item.icon}
            <Stack gap={1.5} mt={3}>
              <CardTitle>{item.title}</CardTitle>
              <CardSubTitle>{item.subtitle}</CardSubTitle>
            </Stack>
          </Stack>

          {/* <LearnBtnWrapper>
            <LearnTxt>Learn more</LearnTxt>
            <ChRightIcon />
          </LearnBtnWrapper> */}
        </WorkCardBox>
      </Grid>
    ));

  return (
    <SectionWrapper>
      <Grid container justifyContent={'space-between'} mb={5} spacing={3}>
        <Grid item xs={12} md={6}>
          <Stack gap={2}>
            <MainTitle>How Prodculator works</MainTitle>
            {/* <SubTitle>
              The creative community by connecting creative professionals with
              global funders and international Grants.
            </SubTitle> */}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            sx={{
              width: '100%',
            }}
          >
            <TabContainer>
              <TabButton
                active={activeTab === 'creative'}
                onClick={() => setActiveTab('creative')}
              >
                <Typography>Project Owners</Typography>
              </TabButton>
              <TabButton
                active={activeTab === 'funders'}
                onClick={() => setActiveTab('funders')}
              >
                <Typography>For Funders</Typography>
              </TabButton>
            </TabContainer>
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {activeTab === 'creative'
          ? renderCards(creativeData)
          : renderCards(fundersData)}
      </Grid>
    </SectionWrapper>
  );
};

export default HowWorks;
