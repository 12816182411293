import { useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Link,
  Typography,
  Menu,
  MenuItem,
  Button,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { KeyboardArrowDown as ArrowDownIcon } from '@mui/icons-material';

import { ReactComponent as FbIcon } from 'assets/images/landing/fb.svg';
import { ReactComponent as InIcon } from 'assets/images/landing/in.svg';
import { ReactComponent as InstaIcon } from 'assets/images/landing/insta.svg';
import { ReactComponent as XIcon } from 'assets/images/landing/x.svg';

import Logo from 'ui-component/Logo';
import { useNavigate } from 'react-router-dom';

// =============================|| LANDING - FOOTER SECTION ||============================= //

const SectionWrapper = styled(Box)(({ theme }) => ({
  padding: '60px 60px 10px',
  background: theme.palette.secondary.main,

  [theme.breakpoints.down('md')]: {
    padding: '24px',
  },
}));

const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 8, // Provide extra padding for better spacing
  ':hover': {
    background: '#FFCF421A', // Subtle hover effect
  },
}));

const StyledLink = styled(Typography)(({ theme }) => ({
  marginRight: 20, // Add space between items
  cursor: 'pointer',
  whiteSpace: 'nowrap', // Prevent word wrapping
  '&:hover': {
    textDecoration: 'underline', // Underline on hover for links
  },
}));

const WebsiteTxt = styled(Link)(({ theme }) => ({
  marginRight: 20, // Add space between items
  cursor: 'pointer',
  color: '#232322',
  whiteSpace: 'nowrap', // Prevent word wrapping
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline', // Underline on hover for links
  },
}));

const StyledAnchor = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  color: 'inherit',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const LinkStack = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: theme.spacing(3, 0),
    gap: theme.spacing(1),
  },
}));

const SocialStack = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
  justifyContent: 'flex-end',

  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
  },
}));

const CenterResponsiveBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderTop: '1px solid #1111111A',
  padding: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
  },
}));

const EndResponsiveBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
  },
}));

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [privacyMenuAnchor, setPrivacyMenuAnchor] = useState(null);

  const scrollToSection = (section) => {
    const headerOffset = 64; // adjust this value based on your header height
    const element = document.getElementById(section);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleAboutUsClick = () => {
    navigate('/about-us');
  };

  const handleContactUsClick = () => {
    navigate('/');
    setTimeout(() => {
      scrollToSection('contact-us');
    }, 100);
  };

  const handleHomeClick = () => {
    navigate('/');
    window.scrollTo(0, 0);
  };

  const handlePrivacyClick = (event) => {
    setPrivacyMenuAnchor(event.currentTarget);
  };

  const handleClosePrivacyMenu = () => {
    setPrivacyMenuAnchor(null);
  };

  const handleNavigateUkPrivacy = () => {
    navigate('/uk-privacy-policy');
    setPrivacyMenuAnchor(null);
  }

  const handleNavigateUsPrivacy = () => {
    navigate('/us-privacy-policy');
    setPrivacyMenuAnchor(null);
  }

  return (
    <SectionWrapper>
      <Box>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item xs={12} md={4}>
            <EndResponsiveBox>
              <Logo />
            </EndResponsiveBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <LinkStack direction={'row'} justifyContent="center">
              <StyledLink onClick={handleHomeClick}>Home</StyledLink>
              <WebsiteTxt
                
                target="_blank"
                component={Link}
                onClick={handleAboutUsClick}
              >
                About us
              </WebsiteTxt>
              <StyledLink onClick={handleContactUsClick}>Contact us</StyledLink>
              <StyledAnchor href="/Terms of Use.pdf" target="_blank">
                Terms
              </StyledAnchor>

              {/* Privacy Policy dropdown trigger */}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  onClick={handlePrivacyClick}
                  sx={{
                    textTransform: 'none',
                    padding: 0,
                    minWidth: 'auto',
                    color: 'inherit',
                    '&:hover': { textDecoration: 'underline' },
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="body1" sx={{ mr: 0.5 }}>
                    Privacy policy
                  </Typography>
                  <ArrowDownIcon fontSize="small" />
                </Button>
                <Menu
                  anchorEl={privacyMenuAnchor}
                  open={Boolean(privacyMenuAnchor)}
                  onClose={handleClosePrivacyMenu}
                  transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                >
                  <MenuItem
                    component="a"
                    
                    target="_blank"
                    onClick={handleNavigateUkPrivacy}
                    sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}
                  >
                    UK Privacy Policy
                  </MenuItem>
                  <MenuItem
                    component="a"
                    
                    target="_blank"
                    onClick={handleNavigateUsPrivacy}
                    sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}
                  >
                    US Privacy Policy
                  </MenuItem>
                </Menu>
              </Box>
            </LinkStack>
          </Grid>

          <Grid item xs={12} md={4}>
            <SocialStack>
              <IconButtonWrapper
                aria-label="instagram"
                component={Link}
                target="_blank"
                href="https://www.instagram.com/prodculator/"
              >
                <InstaIcon />
              </IconButtonWrapper>

              <IconButtonWrapper
                aria-label="facebook"
                component={Link}
                target="_blank"
                href="https://www.facebook.com/prodculator"
              >
                <FbIcon />
              </IconButtonWrapper>

              <IconButtonWrapper
                aria-label="linkedin"
                component={Link}
                target="_blank"
                href="https://www.linkedin.com/company/prodculator/"
              >
                <InIcon />
              </IconButtonWrapper>

              <IconButtonWrapper
                aria-label="x"
                component={Link}
                target="_blank"
                href="https://x.com/prodculator"
              >
                <XIcon />
              </IconButtonWrapper>
            </SocialStack>
          </Grid>
        </Grid>

        <Grid
          container
          mt={{ md: 8, sm: 2, xs: 1 }}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Grid item xs={12}>
            <CenterResponsiveBox>
              <Typography
                variant="h5"
                fontWeight={400}
                sx={{
                  color: '#11111180',
                }}
              >
                © 2025 Prodculator. All rights reserved
              </Typography>
            </CenterResponsiveBox>
          </Grid>
        </Grid>
      </Box>
    </SectionWrapper>
  );
};

export default Footer;
