/* eslint-disable react/prop-types */
import { useTheme } from '@emotion/react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import AnimateButton from 'ui-component/extended/AnimateButton';

const SecondaryButton = (props) => {
  const user = useSelector((state) => state.auth.authData.data?.userType);
  const theme = useTheme();
  console.log('user?.name', user?.name === 'Funder');
  return (
    <>
      <AnimateButton>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          {...props}
          sx={{
            background: user?.name
              ? user?.name === 'Funder'
                ? '#232323'
                : theme.palette.secondary.main
              : theme.palette.secondary.main,
            color: user?.name
              ? user?.name === 'Funder'
                ? theme.palette.common.white
                : theme.palette.common.black
              : theme.palette.common.black,
            '&.Mui-disabled': {
              backgroundColor: 'secondary.dark',
              cursor: 'not-allowed !important',
            },

            ':hover': {
              background: user?.name
                ? user?.name === 'Funder'
                  ? '#232323'
                  : theme.palette.secondary.dark
                : theme.palette.secondary.dark,
              boxShadow: 'none',
            },
          }}
        >
          {props?.isLoader ? (
            <Box
              sx={{
                mx: 4,
              }}
            >
              <CircularProgress size={24} color="black" />
            </Box>
          ) : (
            props.title
          )}{' '}
        </Button>
      </AnimateButton>
    </>
  );
};

export default SecondaryButton;
