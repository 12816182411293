import { useState, useEffect } from 'react';
// project imports
import CookieConsent from 'views/common/CookieConsent';
import ContactUs from './ContactUs';
import HeroSection from './HeroSection';
import HowWorks from './HowWorks';
import OurPartners from './OurPartners';
import SectorSection from './SectorSection';
import TermsAndConditions from './TermsAndConditions';

// Constants
const COOKIE_CONSENT_KEY = 'cookieConsent';
const COOKIE_CONSENT_EXPIRY_DAYS = 180; // Ask again after 6 months

// Helper function to check if consent has expired
const isConsentExpired = (lastConsentDate) => {
  const consentDate = new Date(lastConsentDate);
  const now = new Date();
  const expiryDate = new Date(consentDate);
  expiryDate.setDate(consentDate.getDate() + COOKIE_CONSENT_EXPIRY_DAYS);
  return now > expiryDate;
};

// =============================|| LANDING MAIN ||============================= //

const Landing = () => {
  const [isCookieConsentOpen, setIsCookieConsentOpen] = useState(false);

  useEffect(() => {
    // Check localStorage for saved cookie consent
    const savedConsent = localStorage.getItem(COOKIE_CONSENT_KEY);

    if (!savedConsent || isConsentExpired(savedConsent)) {
      // If no consent or consent has expired, open the consent dialog
      setIsCookieConsentOpen(true);
    }
  }, []);

  const handleConsent = () => {
    // Save the consent date to localStorage
    const now = new Date().toISOString();
    localStorage.setItem(COOKIE_CONSENT_KEY, now);

    // Close the consent dialog
    setIsCookieConsentOpen(false);
  };

  return (
    <>
      {/* <TermsAndConditions /> */}
      <div id="hero-section">
        <HeroSection />
      </div>

      <OurPartners />

      <HowWorks />

      <SectorSection />
      <div id="contact-us">
        <ContactUs />
      </div>

      {/* Show the cookie consent dialog if it's open */}
      <CookieConsent
        isOpen={isCookieConsentOpen}
        setIsOpen={setIsCookieConsentOpen}
        onConsent={handleConsent}
      />
    </>
  );
};

export default Landing;
