import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as RoundIcon } from 'assets/images/svg/round-icon.svg';
import CheckedIcon from 'assets/images/svg/checked.svg';
import PropTypes from 'prop-types';
import { Button, ListItemIcon, styled, useTheme } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';

const StepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#E0E0E0',
  zIndex: 1,
  height: '20px',
  width: '20px',
  color: '#fff',
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: `url(${CheckedIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20px 20px',
  }),
  ...(ownerState.completed && {
    backgroundImage: `url(${CheckedIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '20px 20px',
  }),
}));

function StepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <RoundIcon />,
    2: <RoundIcon />,
    3: <RoundIcon />,
  };

  return (
    <StepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </StepIconRoot>
  );
}

StepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const steps = [
  {
    label: 'About you',
    description: 'Please provide your details',
  },
  {
    label: 'About Your Project',
    description: 'Please provide your project details',
  },
  {
    label: 'Required Documents',
    description: 'Upload your documents',
  },
  {
    label: 'Review',
    description: 'View project and add project',
  },
];

export default function StepForm() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentStep } = useSelector((state) => state.common);
  const handleLogout = () => {
    navigate('/login');
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Stepper
        activeStep={currentStep}
        orientation="vertical"
        sx={{
          '& .MuiStepConnector-line': {
            minHeight: '32px',
            borderLeftStyle: 'dashed',
          },
        }}
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={StepIcon}
              optional={<Typography>{step.description}</Typography>}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box
        sx={{ mt: 'auto', display: 'flex', alignItems: 'center', padding: 2 }}
      >
        <ListItemIcon>
          <LogoutIcon
            fontSize="small"
            sx={{ color: theme.palette.error.main }}
          />
        </ListItemIcon>
        <Typography
          variant="inherit"
          onClick={handleLogout}
          sx={{ color: theme.palette.error.main, mr: 1, cursor: 'pointer' }}
        >
          Logout
        </Typography>
      </Box>
    </Box>
  );
}
